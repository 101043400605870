import React, { Fragment } from 'react';

type ContentfulResponsivePicture = {
  src: string;
  alt?: string;
  breakpointWidthMap: { [breakpoint: number]: number };
  devicePixelRatios?: number[];
  className?: string;
  aspectRatio?: number; // (w / h)
  loading?: 'lazy' | 'eager';
};

const ContentfulResponsivePicture: React.FC<ContentfulResponsivePicture> = ({
  src,
  alt,
  breakpointWidthMap,
  devicePixelRatios = [1, 2, 3],
  className,
  aspectRatio,
  loading,
}) => {
  const breakpointPixels = Object.keys(breakpointWidthMap)
    .map(Number)
    .sort((a, b) => a - b);

  const formats = [
    { format: 'webp', type: 'image/webp' },
    { format: 'jpg', type: 'image/jpeg' },
  ];

  const sources = breakpointPixels
    .map(breakpointPx => {
      const width = breakpointWidthMap[breakpointPx];
      return formats.map(({ format, type }) => {
        const srcSets = devicePixelRatios.map(devicePixelRatio => {
          const query = new URLSearchParams({
            w: (width * devicePixelRatio).toFixed(0),
            fm: format,
            ...(aspectRatio ? { h: Math.floor(width / aspectRatio).toFixed(0), fit: 'fill' } : {}),
            q: '50',
          });

          return `${src}?${query.toString()} ${devicePixelRatio}x`;
        });

        return (
          <source
            key={`${breakpointPx}-${format}`}
            media={`(min-width: ${breakpointPx}px)`}
            srcSet={srcSets.join(', ')}
            type={type}
          />
        );
      });
    })
    .reverse();

  return (
    <picture className={className}>
      {sources.length ? <Fragment>{sources}</Fragment> : <Fragment />}
      <img src={src} alt={alt} loading={loading} />
    </picture>
  );
};

export default ContentfulResponsivePicture;
