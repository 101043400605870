import React from 'react';
import { NextUpPostType } from 'libs/contentful/types';
import ArticleCarouselSection from '../ArticleCarouselSection';

type FeaturedSectionProps = {
  featured?: NextUpPostType[];
  className?: string;
};
const FeaturedSection: React.FC<FeaturedSectionProps> = ({ featured, className }) => {
  if (!featured) {
    return null;
  }
  return <ArticleCarouselSection title="Featured" posts={featured} className={className} />;
};

export default FeaturedSection;
