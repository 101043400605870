import React from 'react';
import { NextUpPostType } from 'libs/contentful/types';
import ArticleCarouselSection from '../ArticleCarouselSection';

type NextUpSectionProps = {
  nextUp?: NextUpPostType[];
  className?: string;
};
const NextUpSection: React.FC<NextUpSectionProps> = ({ nextUp, className }) => {
  if (!nextUp) {
    return null;
  }
  return <ArticleCarouselSection title="Up next for you" posts={nextUp} className={className} />;
};

export default NextUpSection;
