import styled from '@emotion/styled';
import {
  AccordionSection,
  AccordionSectionHeading,
} from '@zola/zola-ui/src/components/AccordionV2/AccordionV2.styles';
import { SidebarContentWrapper } from '@zola/zola-ui/src/components/PreAuthV3/Masthead.styles';
import { ContentWrapper } from '@zola/zola-ui/src/components/PreAuthV3/SupportAccordion.styles';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import MEDIA_QUERY_V2, { SCREEN_MASSIVE } from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import { VERTICAL_NAV_WIDTH } from 'hooks/useIsPostAuthNav3Active';

export const PageWrapper = styled.div<{
  isPostAuthNav3?: boolean;
}>`
  ${MEDIA_QUERY_V2.DESKTOP_MASSIVE} {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div,
    > nav,
    > section {
      width: 100%;
      max-width: ${SCREEN_MASSIVE}px;
      border-left: ${({ isPostAuthNav3 }) =>
        isPostAuthNav3 ? null : `1px solid ${COLORS3.BLACK_030}`};
      border-right: ${({ isPostAuthNav3 }) =>
        isPostAuthNav3 ? null : `1px solid ${COLORS3.BLACK_030}`};
    }
  }

  ${MEDIA_QUERY.DESKTOP} {
    margin-left: ${props => (props.isPostAuthNav3 ? `${VERTICAL_NAV_WIDTH}px` : '0')};

    ${SidebarContentWrapper} {
      max-width: 680px;
    }
  }

  // something is different in how emotion is compiling in zola-ui vs. here
  // in zola-ui this styling is already applied, but the order is changed in web-preauth
  // so adding here to ensure the styles are correct
  ${ContentWrapper} {
    ${AccordionSection} {
      &:hover {
        ${AccordionSectionHeading} {
          color: ${COLORS3.WHITE_100};
        }
      }
    }
  }
`;

export default PageWrapper;
