import styled from '@emotion/styled';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

export const ExpertAdviceFooterContainer = styled.div<{ noBorder?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${COLORS3.CLOUD_100};
  border-top: ${({ noBorder }) => (noBorder ? null : `1px solid ${COLORS3.BLACK_030}`)};
  padding: ${SPACING.LG};

  ${MEDIA_QUERY_V2.MOBILE} {
    padding: ${SPACING.S32} ${SPACING.S24};
  }
`;

export const FooterTitleContainer = styled.div`
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    text-align: center;
  }
`;

export const FooterTitle = styled(H.TitleMedium3)`
  font-weight: ${FONT.WEIGHT.REGULAR};
`;

export const FooterSubtitle = styled(P.BodyBase)`
  margin-top: ${SPACING.XS};
  margin-bottom: ${SPACING.LG};

  ${MEDIA_QUERY_V2.MOBILE} {
    margin-bottom: ${SPACING.S32};
  }
`;

export const FooterBodyContainer = styled.div`
  margin: auto;
  column-count: 3;
  column-gap: ${SPACING.S32};

  ${MEDIA_QUERY_V2.MOBILE_XS} {
    column-count: 2;
  }

  ${MEDIA_QUERY_V2.MOBILE} {
    column-gap: ${SPACING.S24};
  }

  ${MEDIA_QUERY_V2.TABLET} {
    column-count: 4;
  }

  ${MEDIA_QUERY_V2.DESKTOP} {
    column-count: 5;
  }

  ${MEDIA_QUERY_V2.DESKTOP_LG} {
    column-count: 6;
  }

  ${MEDIA_QUERY_V2.DESKTOP_XL} {
    column-count: 7;
  }
`;

export const FooterTopicContainer = styled.div`
  overflow: hidden;
  margin-bottom: ${SPACING.S24};
  ${MEDIA_QUERY_V2.MOBILE} {
    margin-bottom: ${SPACING.S16};
  }
`;

export const FooterTopicHeading = styled(H.TitleMedium4)`
  ${MEDIA_QUERY_V2.MOBILE} {
    ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
  }
`;

export const FooterSubtopicListItem = styled.li`
  :first-of-type {
    margin-top: ${SPACING.XS};
  }
  :not(:last-child) {
    margin-bottom: ${SPACING.XXS};
    ${MEDIA_QUERY_V2.MOBILE} {
      margin-bottom: ${SPACING.XS};
    }
  }
`;

export const FooterSubtopicLink = styled(LinkV2)`
  ${FONT.textSizeCompact(FONT.SIZE.SMALLER)}
  color: ${COLORS3.BLACK_075};
`;
