import React from 'react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import type { ExpertAdviceFooterType } from 'libs/contentful/types';

import { EA_PATH_TOPIC } from 'constants/expertAdvice';

import {
  ExpertAdviceFooterContainer,
  FooterBodyContainer,
  FooterSubtitle,
  FooterSubtopicLink,
  FooterSubtopicListItem,
  FooterTitle,
  FooterTitleContainer,
  FooterTopicContainer,
  FooterTopicHeading,
} from './ExpertAdviceFooter.styles';

type ExpertAdviceFooterProps = {
  expertAdviceFooter: ExpertAdviceFooterType;
  noBorder?: boolean;
};

const ExpertAdviceFooter = ({
  expertAdviceFooter,
  noBorder = false,
}: ExpertAdviceFooterProps): JSX.Element => (
  <ExpertAdviceFooterContainer noBorder={noBorder}>
    <FooterTitleContainer>
      <FooterTitle>Find even more wedding ideas, inspo, tips, and tricks</FooterTitle>
      <FooterSubtitle>
        We’ve got wedding planning advice on everything from save the dates to wedding cakes.
      </FooterSubtitle>
    </FooterTitleContainer>
    <FooterBodyContainer>
      {expertAdviceFooter.topics.map(({ name, slug, subtopics }) =>
        slug ? (
          <FooterTopicContainer key={slug}>
            <LinkV2 href={`${EA_PATH_TOPIC}/${slug}`} noUnderline noTextTransform>
              <FooterTopicHeading presentation="h6">{name}</FooterTopicHeading>
            </LinkV2>
            {subtopics && subtopics.length > 0 ? (
              <ul>
                {subtopics?.map(({ name: subtopicName, slug: subtopicSlug }) =>
                  subtopicSlug ? (
                    <FooterSubtopicListItem key={subtopicSlug}>
                      <FooterSubtopicLink
                        href={`${EA_PATH_TOPIC}/${slug}/${subtopicSlug}`}
                        noUnderline
                        noTextTransform
                        subtle
                      >
                        {subtopicName}
                      </FooterSubtopicLink>
                    </FooterSubtopicListItem>
                  ) : (
                    <></>
                  )
                )}
              </ul>
            ) : (
              <></>
            )}
          </FooterTopicContainer>
        ) : (
          <></>
        )
      )}
    </FooterBodyContainer>
  </ExpertAdviceFooterContainer>
);

export default ExpertAdviceFooter;
